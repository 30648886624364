import { lazy } from "react";
import { Navigate } from "react-router";
import AuthGuard, { UserGuard } from "src/guards/AuthGuard";
import Layout from "src/layouts/layout";
import UserGuidanceProvider from "src/store/user-guidance";
import Loadable from "../Loadable";
import businessBuilder from "./businessBuilder";
import findSponser from "./findSponser";
import helpCenter from "./helpCenter";
import profile from "./profile";
import subscriptions from "./subscriptions";

const ProductList = Loadable(
    lazy(() =>
        import(
            "src/pages/userSide/onlineStore/productSubscription/productList/index"
        )
    )
);

const ProductDetails = Loadable(
    lazy(() =>
        import(
            "src/pages/userSide/onlineStore/productSubscription/details/index"
        )
    )
);

const Checkout = Loadable(
    lazy(() =>
        import(
            "src/pages/userSide/onlineStore/productSubscription/checkout/index"
        )
    )
);

const MyOrders = Loadable(
    lazy(() => import("src/pages/userSide/onlineStore/myOrders/index"))
);

const InvoiceDetails = Loadable(
    lazy(() => import("src/pages/dashboard/invoice-details/index"))
);

const BlogPosts = Loadable(
    lazy(() => import("src/pages/userSide/blogs/BlogPosts"))
);

const BlogPost = Loadable(
    lazy(() => import("src/pages/userSide/blogs/BlogPost"))
);

const Dashboard = Loadable(
    lazy(() => import("src/pages/userSide/dashboard/index"))
);

const IncomeReport = Loadable(
    lazy(() => import("src/pages/userSide/incomeReport"))
);

// const MissedPoints = Loadable(
//     lazy(() => import("src/pages/userSide/missedPoints"))
// );

const Events = Loadable(lazy(() => import("src/pages/userSide/events/index")));

const Cart = Loadable(
    lazy(() =>
        import(
            "src/pages/userSide/onlineStore/productSubscription/checkout/components/cart/index"
        )
    )
);

const Payment = Loadable(
    lazy(() =>
        import(
            "src/pages/userSide/onlineStore/productSubscription/checkout/components/payment/index"
        )
    )
);
const TotalCommission = Loadable(
    lazy(() => import("src/pages/userSide/profile/commission/index"))
);

const user = [
    {
        path: "user",
        element: (
            <AuthGuard>
                <UserGuard>
                    <UserGuidanceProvider>
                        <Layout />
                    </UserGuidanceProvider>
                </UserGuard>
            </AuthGuard>
        ),
        children: [
            { element: <Navigate to='dashboard' />, index: true },
            {
                path: "dashboard",
                element: <Dashboard />,
            },
            {
                path: "checkout",
                element: <Checkout />,
                children: [
                    { index: true, element: <Cart /> },
                    { path: "payment", element: <Payment /> },
                ],
            },
            {
                path: "events",
                element: <Events />,
            },
            {
                path: "online-store",
                children: [
                    {
                        path: "product-subscription",
                        children: [
                            { index: true, element: <ProductList /> },
                            { path: ":name", element: <ProductDetails /> },
                            { path: ":pid/:name", element: <ProductDetails /> },
                        ],
                    },
                    {
                        path: "my-orders",
                        children: [
                            { index: true, element: <MyOrders /> },
                            {
                                path: ":id",
                                element: <InvoiceDetails />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "blogs",
                children: [
                    { index: true, element: <BlogPosts /> },
                    { path: ":id", element: <BlogPost /> },
                ],
            },
            { path: "income-report", element: <IncomeReport /> },
            { path: "total-commission", element: <TotalCommission /> },
            { ...businessBuilder },
            { ...subscriptions },
            { ...helpCenter },
            { ...profile },
            { ...findSponser },
        ],
    },
];

export default user;
