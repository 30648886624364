import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

import { WagmiConfig } from "wagmi";
import { arbitrum, mainnet, zkSync } from "wagmi/chains";
import { PROJECT_ID } from "./config";
// import * as chains from "wagmi/chains";
// console.log(chains);

import "./wagmi-style.css";
// Replace this project ID with yours
// from cloud.walletconnect.com
const projectId = PROJECT_ID;

const chains = [mainnet, arbitrum, zkSync];

const wagmiConfig = defaultWagmiConfig({
    projectId,
    chains,
    metadata: {
        name: "test",
    },
});

createWeb3Modal({
    chains,
    projectId,
    wagmiConfig,
});

const WalletConnect = ({ children }) => {
    return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};

export default WalletConnect;

