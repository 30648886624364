import { useState, useCallback } from "react";
import useQueryParams from "src/hooks/useQueryParams";

const usePagination = () => {
    const { queryObject, addParam, deleteParam } = useQueryParams();
    const [count, setCount] = useState(0);
    const [rowStart, setRowStart] = useState(1);

    const [page, setPage] = useState(() => {
        const { page } = queryObject;

        if (page) return parseInt(page);
        return 1;
    });

    const seed = useCallback((totalPages, rowStart = 1) => {
        setCount(totalPages);
        setRowStart(rowStart);
    }, []);

    const onChange = useCallback(
        (_, page) => {
            setPage(page);
            // addParam("page", page);
            sessionStorage.setItem("page", page);
        },
        [addParam]
    );

    const reset = () => {
        deleteParam("page");
        sessionStorage.removeItem("page", page);
    };

    return { reset, count, page, seed, onChange, rowStart };
};

export default usePagination;
