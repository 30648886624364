import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import UsersSearch from "src/components/autoComplete/users";
import GetReport from "src/components/getReport";
import {
    FormProvider,
    RHFSelect,
    RHFTextField,
} from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import useLocales from "src/hooks/useLocales";
import useQueryParams from "src/hooks/useQueryParams";
import Product from "../components/Product";
import PaymentMethods from "../transaction/components/filter/components/payment-methods";
import { TwoPrecisionRound } from "src/components/helpers";
import { Dollar } from "src/components/with-prefix";
import SalesRefundOption from "./components/select-refund-option";
import Products from "./products";

const SalesFilter = ({ methods, onFilter, total }) => {
    const { translate } = useLocales();
    const { queryObject } = useQueryParams();
    const { start_date, end_date, status } = queryObject;
    const { setValue } = methods;

    useEffect(() => {
        if (start_date) {
            setValue("start_date", start_date);
        }

        if (end_date) {
            setValue("end_date", end_date);
        }

        if (status) {
            setValue("status", status);
        }
    }, [start_date, end_date, status, setValue]);

    return (
        <div>
            <FormProvider methods={methods} onSubmit={onFilter}>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "repeat(1,1fr)",
                            sm: "repeat(5, 1fr)",
                        },
                        rowGap: 3,
                        columnGap: 3,
                    }}>
                    <RHFDatePicker
                        label={translate("adminFinancial.payout.pickStartDate")}
                        name='start_date'
                        size='small'
                    />
                    <RHFDatePicker
                        label={translate("adminFinancial.payout.pickEndDate")}
                        name='end_date'
                        size='small'
                    />

                    {/* <UsersSearch name='user_id' props={{ size: "small" }} /> */}
                    <RHFSelect name='include' size='small' label='status'>
                        <option value=''></option>
                        <option value={1}>Include</option>
                        <option value={0}>Exclude</option>
                    </RHFSelect>
                    <Products
                        name='product_id'
                        inputProps={{ size: "small" }}
                        minWidth='300px'
                        multiple
                    />
                    <GetReport size='medium' />
                </Box>
            </FormProvider>
        </div>
    );
};

export default SalesFilter;
