import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const NetworkMembers = Loadable(
    lazy(() => import("src/pages/members/network"))
);

const MemberProfile = Loadable(lazy(() => import("src/pages/members/Profile")));

const HoldingTank = Loadable(
    lazy(() => import("src/pages/members/holdingTank/index"))
);

const BusinessBuilder = Loadable(
    lazy(() => import("src/pages/members/business-builder/index"))
);
const TotalCommission = Loadable(
    lazy(() =>
        import(
            "src/pages/members/Profile/components/ProfileBanner/totak-commission"
        )
    )
);
const members = [
    {
        path: "members",
        children: [
            { element: <Navigate to='network' />, index: true },
            { path: "network", element: <NetworkMembers /> },
            { path: "business-builder", element: <BusinessBuilder /> },
            {
                path: "holding-tank",
                element: <HoldingTank />,
            },
            { path: "profile/:mid", element: <MemberProfile /> },
            { path: "total-commission/:mid", element: <TotalCommission /> },
        ],
    },
];

export default members;
