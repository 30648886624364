import { useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import CopyButton from "src/components/copy-button";
import Ternary from "src/components/ternary";
import buildPath from "src/utils/buildPath";

const WhisperDropLinkItem = ({ address, base, length = 10 }) => {
    const { palette, breakpoints } = useTheme();

    const URL = useMemo(() => {
        if (base) {
            return buildPath(base, address);
        }
        return null;
    }, [base]);

    return (
        <Ternary
            when={URL}
            then={
                <>
                    <Tooltip
                        title={address}
                        sx={{
                            width: "500px",
                            whiteSpace: "nowrap",
                        }}
                        arrow
                        placement='top'>
                        <a
                            href={URL}
                            target='_blank'
                            style={{
                                color: palette.primary.main,
                                fontSize: "13px",
                            }}>
                            {address.slice(0, length)}...
                        </a>
                    </Tooltip>
                    <CopyButton value={address} />
                </>
            }
        />
    );
};

export default WhisperDropLinkItem;
