// routes
import { parseInt } from "lodash";
import { PATH_DASHBOARD } from "./routes/paths";

// API
// ----------------------------------------------------------------------

export const AUTH_INFO = {
    user: {
        email: process.env.REACT_APP_USER_USERNAME || "",
        password: process.env.REACT_APP_USER_PASSWORD || "",
    },
    admin: {
        email: process.env.REACT_APP_ADMIN_USERNAME || "",
        password: process.env.REACT_APP_ADMIN_PASSWORD || "",
    },
};

export const TEACHABLE_URL =
    process.env.REACT_APP_TEACHABLE_URL ||
    "https://sso.teachable.com/secure/1422944/identity/login/otp";
export const WENDY_SUBADMIN_ID = parseInt(
    process.env.REACT_APP_WENDY_SUBADMIN_ID
);
export const LIST_HIDE_GIFT = JSON.parse(
    process.env.REACT_APP_HIDE_GIFT || "[]"
);
export const MATERIALS_NOTIFICATION_START_DATE =
    process.env.REACT_APP_MATERIALS_NOTIFICATION_START_DATE || "2025-03-17";
export const SOLANA_RPC_URL =
    process.env.REACT_APP_SOLANA_RPC_URL || "https://api.devnet.solana.com";

export const ALREADY_CONNECTED_WALLET_STEPS_FAQ_PATH =
    process.env.REACT_APP_ALREADY_CONNECTED_WALLET_STEPS_FAQ_PATH || "";
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || "";
export const NETWORK = process.env.REACT_APP_NETWORK;
export const MULTI_TRANSFER_CONTRACT =
    process.env.REACT_APP_MULTI_TRANSFER_CONTRACT;
export const BTAF_TOKEN = process.env.REACT_APP_BTAF_TOKEN;
export const BTAF_PRODUCT = process.env.REACT_APP_BTAF_PRODUCT;
export const SPECIAL_CONTRACT = process.env.REACT_APP_SPECIAL_CONTRACT;

export const BTAF_CONTRACT_LIST = JSON.parse(
    process.env.REACT_APP_BTAF_STAKING_CONTRACT
);

export const SHOW_BTAF_MENUS = process.env.REACT_APP_SHOW_BTAF_MENUS === "TRUE";
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK || "";

export const MAINTENANCE_MODE =
    process.env.REACT_APP_MAINTENANCE_MODE === "TRUE";

export const SPECIAL_SUBSCRIPTION_ID = parseInt(
    process.env.REACT_APP_SPECIAL_SUBSCRIPTION_ID
);

export const BTAF_PRODUCT_ID = 152;
export const START_COURSE_PRODUCT_ID = JSON.parse(
    process.env.REACT_APP_START_COURSE_PRODUCT_ID
);
export const ADOBE_ID = process.env.REACT_APP_ADOBE_ID;
export const WEBSITE_URL =
    process.env.REACT_APP_WEBSITE_URL || window.location.origin;
export const HOST_API = process.env.REACT_APP_HOST_NAME || "";
export const DEV_MODE = process.env.REACT_APP_DEV_MODE === "TRUE";
export const GOOGLE_API = {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    captchaKey: process.env.REACT_APP_CAPTCHA_ID,
};
export const FIREBASE_API = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: "light",
    themeDirection: "ltr",
    themeColorPresets: "default",
    themeLayout: "horizontal",
    themeStretch: false,
};
