import { useSnackbar } from "notistack";
import React, { useState } from "react";
import axiosInstance from "src/utils/axios";

const useDeleteAdminReplay = (reload) => {
    const [load, setLoad] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const deleteReplay = async (id) => {
        setLoad(true);
        const reqData = new FormData();
        reqData.append("_method", "DELETE");
        try {
            const { data, status } = await axiosInstance.post(
                `api/admin/support-ticket-replies/${id}`,
                reqData
            );
            if (status === 200) {
                enqueueSnackbar(data.message, { variant: "success" });
                reload();
            }
            setLoad(false);
        } catch (err) {
            console.log(err);
            enqueueSnackbar(err.message, { variant: "error" });
            setLoad(false);
        }
    };
    return { load, deleteReplay };
};

export default useDeleteAdminReplay;
