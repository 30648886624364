import { Autocomplete, TextField, Chip } from "@mui/material";
import { useFormContext } from "react-hook-form";
import useLocales from "src/hooks/useLocales";
import useProductList from "./hooks/useProductList";

const Products = ({
    name,
    multiple,
    limitTags = 1,
    label = "Products",
    type,
    inputProps = {},
    fontSize = "8px", // Custom font size prop
    minWidth = "100%",
}) => {
    const options = useProductList(type);
    const { translate } = useLocales();

    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const selected = watch(name);
    const value = multiple
        ? options.filter(({ id }) => selected.includes(id)) || []
        : options.find(({ id }) => id === selected) || null;

    return (
        <Autocomplete
            multiple={multiple}
            options={options}
            limitTags={limitTags}
            value={value}
            getOptionLabel={(option) => option.name} // Keep full name in dropdown
            renderTags={(selectedOptions, getTagProps) =>
                selectedOptions.map((option, index) => (
                    <Chip
                        key={option.id}
                        {...getTagProps({ index })}
                        label={
                            option.name.length > 20
                                ? `${option.name.slice(0, 20)}..`
                                : option.name
                        }
                        onDelete={() => {
                            setValue(
                                name,
                                multiple
                                    ? selected.filter((id) => id !== option.id)
                                    : null
                            );
                        }}
                        sx={{
                            backgroundColor: "rgba(145, 158, 171, 0.16)",
                            borderRadius: "16px",
                            fontSize: "10px",
                            fontWeight: "600",
                            padding: "2px",
                            height: "25px",
                            margin: "2px",
                        }}
                    />
                ))
            }
            onChange={(_, v) => {
                setValue(name, multiple ? v.map(({ id }) => id) : v?.id);
            }}
            sx={{
                minWidth: minWidth,
                "& .MuiAutocomplete-tag": {
                    margin: "1px",
                    maxHeight: "24px",
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    error={Boolean(errors[name])}
                    helperText={errors[name]?.message}
                    label={
                        label
                            ? label
                            : translate("adminSubAdmin.subAdmin.products")
                    }
                    {...inputProps}
                    name={name}
                    sx={{ fontSize: fontSize }} // Apply font size to TextField
                />
            )}
        />
    );
};

export default Products;
