import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import useLocales from "src/hooks/useLocales";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    MenuItem,
    Typography,
    useMediaQuery,
} from "@mui/material";
import useDeleteAdminReplay from "../hook/use-delete-admin-replay";
import { LoadingButton } from "@mui/lab";
const DeleteAdminReplay = ({
    fetchTicket,
    deleteReplayId,
    handleCloseDelete,
}) => {
    const { load, deleteReplay } = useDeleteAdminReplay(() => {
        fetchTicket();
        handleCloseDelete();
    });
    const { translate } = useLocales();
    const { palette } = useTheme();
    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={Boolean(deleteReplayId)}
            onClose={handleCloseDelete}>
            <DialogTitle>Delete Reply</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Box
                        sx={{
                            display: "grid",
                            rowGap: 3,
                            columnGap: 2,
                            marginTop: 3,
                            gridTemplateColumns: {
                                xs: "repeat(1, 1fr)",
                                sm: "repeat(1, 1fr)",
                            },
                        }}>
                        <Typography>
                            Are you sure that you want to continue, this action
                            cannot be reversed.
                        </Typography>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCloseDelete}
                    autoFocus
                    sx={{ color: palette.warning.normal }}>
                    Close
                </Button>
                <LoadingButton
                    loading={load}
                    onClick={() => deleteReplay(deleteReplayId)}
                    variant='contained'
                    color='error'>
                    {translate("adminCommunication.helpCenter.delete")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAdminReplay;
