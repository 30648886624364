import {
    Badge,
    Box,
    Button,
    Chip,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { paramCase } from "change-case";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import Scrollbar from "src/components/Scrollbar";
import { IconButtonAnimate } from "src/components/animate";
import Ternary from "src/components/ternary";
import { BTAF_PRODUCT_ID } from "src/config";
import useAuth from "src/hooks/useAuth";
import useErrors from "src/hooks/useErrors";
import { PATH_USER } from "src/routes/paths";
import fetchUser from "src/utils/fetchUser";

const useChangeNotificationStatus = (fetchData) => {
    const handleError = useErrors();

    const changeStatus = async (id) => {
        const reqData = new FormData();
        reqData.append("_method", "PUT");
        try {
            const { data, status } = await fetchUser.post(
                `notifications/${id}`,
                reqData
            );
            if (status === 200) {
                fetchData();
            }
        } catch (err) {
            handleError(err);
        }
    };

    return changeStatus;
};

const useNotification = () => {
    const { isAdmin, isSubAdmin } = useAuth();
    const [notifications, setNotifications] = useState([]);
    const handleError = useErrors();
    const fetchData = async () => {
        try {
            const { data, status } = await fetchUser("notifications");
            if (status === 200) {
                setNotifications(data.data);
            }
        } catch (err) {
            handleError(err);
        }
    };
    useEffect(() => {
        if (isSubAdmin || isAdmin) return;
        fetchData();
    }, [isSubAdmin, isAdmin]);

    return { notifications, fetchData };
};
export default function NotificationsPopover() {
    const { enqueueSnackbar } = useSnackbar();
    const { notifications, fetchData } = useNotification();
    const totalUnRead = notifications.filter(
        (item) => item.is_read === 0
    ).length;
    const changeStatus = useChangeNotificationStatus(fetchData);
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleMarkAllAsRead = async () => {
        try {
            const { data, status } = await fetchUser.post(
                "real-all-notifications"
            );
            if (status) {
                fetchData();
            }
        } catch (err) {
            enqueueSnackbar(err.message);
            console.log(err);
        }
    };

    return (
        <>
            <IconButtonAnimate
                color={open ? "primary" : "default"}
                onClick={handleOpen}
                sx={{ width: 40, height: 40 }}>
                <Badge badgeContent={totalUnRead} color='error'>
                    <Iconify icon='eva:bell-fill' width={20} height={20} />
                </Badge>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        py: 2,
                        px: 2.5,
                    }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant='subtitle1'>
                            Notifications
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{ color: "text.secondary" }}>
                            You have {totalUnRead} unread messages
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title=' Mark all as read'>
                            <IconButtonAnimate
                                color='primary'
                                onClick={handleMarkAllAsRead}>
                                <Iconify
                                    icon='eva:done-all-fill'
                                    width={20}
                                    height={20}
                                />
                            </IconButtonAnimate>
                        </Tooltip>
                    )}
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <Scrollbar sx={{ maxHeight: 340 }}>
                    <List disablePadding>
                        {notifications.map((notification) => (
                            <NotificationItem
                                key={notification.id}
                                {...notification}
                                handleClose={handleClose}
                                changeStatus={changeStatus}
                            />
                        ))}
                    </List>
                </Scrollbar>

                <Divider sx={{ borderStyle: "dashed" }} />
            </MenuPopover>
        </>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        createdAt: PropTypes.instanceOf(Date),
        id: PropTypes.string,
        isUnRead: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        avatar: PropTypes.any,
    }),
};

function NotificationItem({
    is_read,
    message,
    id,
    changeStatus,
    created_at,
    expiry,
    handleClose,
    product_id,
    products,
}) {
    const navigate = useNavigate();
    return (
        <ListItemButton
            onClick={() => changeStatus(id)}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: "1px",
                ...(!is_read && {
                    bgcolor: "action.selected",
                }),
            }}>
            <ListItemText
                primary={message}
                primaryTypographyProps={{
                    variant: "body2",
                }}
                secondaryTypographyProps={{
                    variant: "caption",
                    marginTop: 1,
                }}
                secondary={
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'>
                        {moment(created_at).format("h:mma DD MMM YYYY")}
                        <Ternary
                            when={Boolean(expiry)}
                            then={
                                <Ternary
                                    when={Boolean(products)}
                                    then={
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleClose();
                                                // navigate(
                                                //     PATH_USER.onlineStore.productSubscription.view(
                                                //         products?.id,
                                                //         paramCase(
                                                //             products?.name || ""
                                                //         )
                                                //     )
                                                // );
                                                navigate(
                                                    PATH_USER.subscriptions.root(
                                                        {
                                                            product_name:
                                                                products?.name ||
                                                                "",
                                                        }
                                                    )
                                                );
                                            }}
                                            size='small'
                                            variant='contained'>
                                            click to renew
                                        </Button>
                                    }
                                />
                            }
                        />
                        <Ternary
                            when={product_id === BTAF_PRODUCT_ID}
                            then={
                                <Button
                                    to={`${PATH_USER.subscriptions.whisperDrop.root}?page=1&tab=claim#claim`}
                                    component={Link}
                                    size='small'
                                    variant='contained'>
                                    click to claim
                                </Button>
                            }
                        />
                    </Stack>
                }
            />
        </ListItemButton>
    );
}
